import DiscoverPage from 'components/DiscoverPage/DiscoverPage';
import { ExploreCourtsProps } from './props';
import useCourt from './useCourt';

const ExploreCourts: React.FC<ExploreCourtsProps> = ({ resultsLimit }) => {
  const { CourtList, courtMapProps, CourtFilters, clearCourtFilters, distance, setDistance } =
    useCourt({ resultsLimit });

  return (
    <>
      <DiscoverPage
        List={CourtList}
        mapProps={courtMapProps}
        distance={distance}
        setDistance={setDistance}
        filterProps={{
          title: 'Filters',
          children: CourtFilters,
          clearAll: clearCourtFilters,
          closeButtonText: 'Show Courts',
        }}
      />
    </>
  );
};

export default ExploreCourts;

import CloseIcon from 'svg/CloseIcon';
import Button from 'components/Button';
import LessonCalendar from 'components/LessonCalendar';
import Modal from 'components/modals/Modal';
import LessonLocationCard from './LessonLocationCard';
import { BookLessonModalProps } from '../props';

const BookLessonModal: React.FC<BookLessonModalProps> = ({
  isOpen,
  handleClose,
  coachAvailability,
  activeLocation,
  activeOwnerLessons,
  selectedDate,
  handleSelectSlot,
  handleBookLesson,
}) => {
  return (
    <Modal
      positionBottomDesktop
      isOpen={isOpen}
      handleClose={handleClose}
      swipeProps={{
        delta: 50,
        trackMouse: true,
        trackTouch: true,
      }}
    >
      <div className="flex h-dvh  w-full flex-col overflow-auto bg-color-bg-lightmode-primary px-6 pt-6 text-color-text-lightmode-primary dark:bg-color-bg-darkmode-primary dark:text-color-text-darkmode-primary">
        <div className="flex items-center justify-between">
          <span className="typography-product-heading-mobile ">Pick a time</span>
          <CloseIcon className="h-5 w-5 cursor-pointer" onClick={handleClose} />
        </div>
        <div className="mt-8 min-h-[15rem]">
          <LessonCalendar
            lessons={activeOwnerLessons || []}
            isOwner={false}
            showName={false}
            isAnonymous
            coachAvailability={coachAvailability}
            onSlotSelect={handleSelectSlot}
          />
        </div>
        <div className="mt-8">
          <span className="typography-product-element-label">Lesson Location</span>
          <LessonLocationCard activeLocation={activeLocation} />
        </div>
        <div className="sticky bottom-0 mt-6 bg-color-bg-lightmode-primary pb-6 pt-2 dark:bg-color-bg-darkmode-primary">
          <Button
            variant="brand"
            onClick={() => {
              handleBookLesson();
              handleClose();
            }}
            disabled={!selectedDate}
            sizeMobile="lg"
          >
            Book a Lesson
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BookLessonModal;

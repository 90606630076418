import { useEffect, useMemo, useState } from 'react';
import {
  CourtSurfacesEnum,
  VenueAccessTypesEnum,
  VenueNetsEnum,
  useGetVenuesByGeoLazyQuery,
} from 'types/generated/client';
import { calculateHaversineDistance } from 'utils/shared/geo/calculateHaversineDistance';
import { milesToMeters } from 'utils/shared/geo/milesToMeters';
import { useGeoLocation } from 'hooks/useGeoLocation';
import { GoogleMapProps } from 'components/GoogleMap/props';
import CourtFilters from './CourtFilters';
import CourtList from './CourtList';
import CourtMapMarker from './CourtMapMarker';
import { CourtType, ExploreCourtsProps } from './props';
import {
  DEFAULT_COURT_DISTANCE_IMPERIAL,
  VENUE_DISTANCE_IMPERIAL_OPTIONS,
  VENUE_NETS_OPTIONS,
  VENUE_SURFACE_OPTIONS,
  getCourtLimit,
} from './utils';

const useCourt = ({ resultsLimit }: ExploreCourtsProps = {}) => {
  const { position, centerLatitude, centerLongitude, isExactCenter, getEstimatedLocation } =
    useGeoLocation();
  const [courtType, setCourtType] = useState<CourtType[]>([]);
  const [surface, setSurface] = useState<CourtSurfacesEnum[]>([]);
  const [nets, setNets] = useState<VenueNetsEnum[]>([]);
  const [accessType, setAccessType] = useState<VenueAccessTypesEnum[]>([]);
  /**
   * @note over 90% of data says permnanet lines which can't be true. It must count tennis courts too which is bad data.
   */
  // const [lines, setLines] = useState('');
  const [courtsMaxNumber, setCourtsMaxNumber] = useState(26);
  const [courtsMinNumber, setCourtsMinNumber] = useState(1);
  const [showOnlyDedicatedCourts, setShowOnlyDedicatedCourts] = useState(false);
  const [showFreeCourts, setShowFreeCourts] = useState(false);
  const [getVenuesByGeoLazyQuery, { data, loading, called }] = useGetVenuesByGeoLazyQuery();
  const [listHoveredVenueId, setListHoveredVenueId] = useState<string | null>(null);
  const [activeVenueId, setActiveVenueId] = useState<string | null>(null);
  const [distance, setDistance] = useState(DEFAULT_COURT_DISTANCE_IMPERIAL);

  const fetchCourts = ({
    longitude,
    latitude,
    distance,
  }: {
    longitude: number;
    latitude: number;
    distance: number;
  }) => {
    getVenuesByGeoLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        limit: !!resultsLimit ? resultsLimit : null,
        distance,
        from: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      },
    });
  };

  useEffect(() => {
    if (position) {
      fetchCourts({
        longitude: position.longitude,
        latitude: position.latitude,
        distance: milesToMeters(DEFAULT_COURT_DISTANCE_IMPERIAL.id),
      });
    }
  }, [position, resultsLimit]);

  useEffect(() => {
    if (!called && position && !data?.venues.length) {
      getVenuesByGeoLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
          distance: milesToMeters(DEFAULT_COURT_DISTANCE_IMPERIAL.id),
          from: {
            type: 'Point',
            coordinates: [centerLongitude, centerLatitude],
          },
        },
      });
    }
  }, [data, position, called]);

  useEffect(() => {
    const getLocation = async () => {
      try {
        getEstimatedLocation();
      } catch (error) {}
    };

    if (!position) {
      getLocation();
    }
  }, [position]);

  const decoratedVenues = useMemo(() => {
    if (!data?.venues || data.venues.length === 0) {
      return [];
    }

    return data.venues.map((venue) => {
      return {
        ...venue,
        distance: calculateHaversineDistance({
          coord1: {
            latitude: centerLatitude || 0,
            longitude: centerLongitude || 0,
          },
          coord2: {
            latitude: venue.geometry.coordinates[1],
            longitude: venue.geometry.coordinates[0],
          },
          unit: 'miles',
        }),
      };
    });
  }, [data?.venues, centerLatitude, centerLongitude]);

  const venues = useMemo(() => {
    const limit = getCourtLimit(decoratedVenues);
    const maxCourts = Math.min(limit, courtsMaxNumber);
    const minCourts = Math.min(maxCourts, courtsMinNumber);

    let venues = decoratedVenues.filter((court) => {
      const totalCourts = (court.indoorCourtCount || 0) + (court.outdoorCourtCount || 0);
      return totalCourts >= minCourts && totalCourts <= maxCourts;
    });

    /**
     * @todo Use array instead of showFreeCourts boolean
     */
    venues =
      accessType.length > 0
        ? venues.filter((venue) => {
            return venue.accessType === VenueAccessTypesEnum.Free;
          })
        : venues;

    if (showOnlyDedicatedCourts) {
      venues = venues.filter((venue) => {
        return (
          venue.pickleballNets === VenueNetsEnum.Permanent ||
          venue.pickleballNets === VenueNetsEnum.Tennis
        );
      });
    } else if (nets.length > 0) {
      let netsIncludes: VenueNetsEnum[] = [];

      nets.forEach((net) => {
        const matchingNet = VENUE_NETS_OPTIONS.find((option) => option.id === net);
        if (!matchingNet) {
          return;
        }
        netsIncludes = [...netsIncludes, ...matchingNet?.includedValues];
      });

      venues = venues.filter((venue) => {
        return !!venue.pickleballNets && nets.includes(venue.pickleballNets);
      });
    }

    if (surface.length > 0) {
      let surfacesIncluded: CourtSurfacesEnum[] = [];

      surface.forEach((surface) => {
        const matchingSurface = VENUE_SURFACE_OPTIONS.find((option) => option.id === surface);
        if (!matchingSurface) {
          return;
        }
        surfacesIncluded = [...surfacesIncluded, ...matchingSurface?.includedValues];
      });

      venues = venues.filter((venue) => {
        let hasSurface = false;
        venue.courtSurfaces.forEach((courtSurface) => {
          if (surfacesIncluded.includes(courtSurface.courtSurface)) {
            hasSurface = true;
          }
        });
        return hasSurface;
      });
    }

    if (courtType.length > 0) {
      const isSearchingIndoor = courtType.includes(CourtType.Indoor);
      const isSearchingOutdoor = courtType.includes(CourtType.Outdoor);

      if (!isSearchingIndoor || !isSearchingOutdoor) {
        if (isSearchingIndoor) {
          venues = venues.filter((venue) => {
            return venue.indoorCourtCount > 0;
          });
        }
        if (isSearchingOutdoor) {
          venues = venues.filter((venue) => {
            return venue.outdoorCourtCount > 0;
          });
        }
      }
    }
    /**
     * @todo dedicated nets, indoor/outdoor (check if the other is zero)... see modal
     */

    return venues.sort((a, b) => a.distance - b.distance);
  }, [
    decoratedVenues,
    courtsMinNumber,
    courtsMaxNumber,
    showFreeCourts,
    showOnlyDedicatedCourts,
    surface,
    courtType,
    nets,
    accessType,
  ]);

  const activeVenue = venues.find((venue) => venue.id === activeVenueId);

  const courtLimit = useMemo(() => {
    return getCourtLimit(decoratedVenues);
  }, [decoratedVenues]);

  const maxCourts = Math.min(courtLimit, courtsMaxNumber);
  const minCourts = Math.min(maxCourts, courtsMinNumber);

  const courtMapProps: GoogleMapProps = {
    defaultCenter: {
      lat: venues[0]?.geometry.coordinates[1] || centerLatitude,
      lng: venues[0]?.geometry.coordinates[0] || centerLongitude,
    },
    zoom: 12,
    markers: venues.map((venue) => ({
      zIndex: activeVenue?.id === venue.id ? 2 : 1,
      lat: venue.geometry.coordinates[1],
      lng: venue.geometry.coordinates[0],
      markerId: venue.id,
      children: (
        <CourtMapMarker
          venue={venue}
          activeVenue={activeVenue}
          setActiveVenueId={setActiveVenueId}
          listHoveredVenueId={listHoveredVenueId}
          setListHoveredVenueId={setListHoveredVenueId}
        />
      ),
    })),
    isExactCenter,
  };

  const clearCourtFilters = () => {
    setNets([]);
    setAccessType([]);
    setCourtType([]);
    setSurface([]);
    setShowOnlyDedicatedCourts(false);
    setShowFreeCourts(false);
    setCourtsMaxNumber(courtLimit);
    setCourtsMinNumber(1);
    setDistance(VENUE_DISTANCE_IMPERIAL_OPTIONS[1]);
    fetchCourts({
      longitude: centerLongitude,
      latitude: centerLatitude,
      distance: milesToMeters(VENUE_DISTANCE_IMPERIAL_OPTIONS[1].id),
    });
  };

  return {
    CourtList: (
      <CourtList
        loading={loading}
        venues={venues}
        setListHoveredVenueId={setListHoveredVenueId}
        listHoveredVenueId={listHoveredVenueId}
      />
    ),
    CourtFilters: (
      <CourtFilters
        setAccessType={setAccessType}
        accessType={accessType}
        courtType={courtType}
        setCourtType={setCourtType}
        setNets={setNets}
        nets={nets}
        setSurface={setSurface}
        surface={surface}
        courtsMinNumber={minCourts}
        setCourtsMinNumber={setCourtsMinNumber}
        showOnlyDedicatedCourts={showOnlyDedicatedCourts}
        setShowOnlyDedicatedCourts={setShowOnlyDedicatedCourts}
        setShowFreeCourts={setShowFreeCourts}
        showFreeCourts={showFreeCourts}
        courtsMaxNumber={maxCourts}
        setCourtsMaxNumber={setCourtsMaxNumber}
        courtLimit={courtLimit}
        distance={distance}
        setDistance={setDistance}
        fetchCourts={fetchCourts}
        centerLatitude={centerLatitude}
        centerLongitude={centerLongitude}
      />
    ),
    courtMapProps,
    clearCourtFilters,
    distance,
    setDistance,
  };
};

export default useCourt;

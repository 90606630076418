import { format } from 'date-fns';
import styled from 'styled-components';
import { getCoachPageUrl } from 'constants/pages';
import { EMPTY_COACH_AVATAR_SRC } from 'constants/user';
import { GetCoachesByGeoQuery } from 'types/generated/client';
import { GetTournamentsForMarketplaceQuery } from 'types/generated/server';
import { VenueAddress } from 'utils/shared/coachBuilder';
import { addOneHour } from 'utils/shared/date/addHourToSlot';
import { calculateHaversineDistance } from 'utils/shared/geo/calculateHaversineDistance';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getProfileImageUrlOrPlaceholder } from 'utils/shared/user/getProfileImageUrlOrPlaceholder';
import { CoachType, CombinedCoachType, OmittedCoachType, TournamentType } from './props';

function formatDateRange(startDate: Date, endDate: Date): string {
  // Formatter for the full date
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Formatter for the month and day
  const monthDayFormatter = new Intl.DateTimeFormat(undefined, {
    month: 'long',
    day: 'numeric',
  });

  // Check if start and end dates are the same day in the user's timezone
  const isSameDay = startDate.toDateString() === endDate.toDateString();

  if (isSameDay) {
    // Format as a single date
    return dateFormatter.format(startDate);
  } else {
    // Format as a date range
    return `${monthDayFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`;
  }
}

export const getDecoratedTournaments = (
  events: GetTournamentsForMarketplaceQuery['events'],
  centerLatitude: number,
  centerLongitude: number,
): TournamentType[] =>
  events.map((tournament) => {
    return {
      ...tournament,
      startTimestamp: new Date(tournament.startDateTime).getTime(),
      displayDate: formatDateRange(
        new Date(tournament.startDateTime),
        new Date(tournament.endDateTime),
      ),
      distance: Math.round(
        calculateHaversineDistance({
          coord1: {
            latitude: tournament.latitude,
            longitude: tournament.longitude,
          },
          coord2: {
            latitude: centerLatitude,
            longitude: centerLongitude,
          },
          unit: 'miles',
        }),
      ),
    };
  });

export const GradientBackground = styled.div<{ left: string }>`
  border-radius: 9999rem;
  position: absolute;
  height: 100%;
  width: auto;
  left: ${({ left }) => left};
  transform: translateX(-50%);
  aspect-ratio: 1/1;
  background: radial-gradient(50% 50% at 50% 50%, #dfecff 0%, rgba(242, 242, 242, 0) 100%);
  z-index: -1;
`;

export const isCoachProfile = (coach: any): coach is GetCoachesByGeoQuery['userProfiles'][0] => {
  return !!coach.username;
};

interface FormatCoachParams {
  coaches: CoachType[];
  centerLatitude: number;
  centerLongitude: number;
}

export const formatCoaches = ({ coaches, centerLatitude, centerLongitude }: FormatCoachParams) => {
  const formattedCoaches = coaches.map((coach) => {
    const price = convertUnitPriceToFormattedPrice(
      isCoachProfile(coach) ? coach.priceUnitAmountCoachDefault || 0 : 0,
    ).priceDisplay;
    const href = getCoachPageUrl(isCoachProfile(coach) ? coach.username : coach.id);
    const imageSrc = isCoachProfile(coach)
      ? getProfileImageUrlOrPlaceholder({
          path: coach.profileImagePath || '',
          placeholder: EMPTY_COACH_AVATAR_SRC,
        })
      : coach.profileImageUrl ||
        coach.aboutPhotoImageUrl ||
        coach.logoImageUrl ||
        EMPTY_COACH_AVATAR_SRC;

    const distance =
      coach.geometry?.coordinates?.[1] && coach.geometry?.coordinates?.[0]
        ? calculateHaversineDistance({
            coord1: {
              latitude: centerLatitude || 0,
              longitude: centerLongitude || 0,
            },
            coord2: {
              latitude: coach.geometry.coordinates[1],
              longitude: coach.geometry.coordinates[0],
            },
            unit: 'miles',
          })
        : 0;

    return {
      ...coach,
      fullName: isCoachProfile(coach) ? coach.fullName : '',
      price,
      href,
      imageSrc,
      distance,
    };
  });

  // Sort by distance
  return formattedCoaches.sort((a, b) => a.distance - b.distance);
};

export const CUSTOM_COURT_IMG_PLACEHOLDER_SRC = '/images/coaches/only-address-venue.png';

export const getLessonSchedularProps = (
  selectedDate: Date,
  activeLocation: VenueAddress,
  activeLesson: CombinedCoachType,
  handleBookAnotherLesson: () => void,
  onPaymentSuccess: () => void,
) => {
  const startTimeString = format(selectedDate, 'hh:mm a');
  const endTimeString = addOneHour(startTimeString);

  return {
    onPaymentSuccess,
    handleBookAnotherLesson,
    fromCalendar: true,
    initialCurrentDateTime: {
      date: selectedDate,
      startTime: startTimeString,
      endTime: endTimeString,
    },
    initialSelectedCourtVenue: {
      id: activeLocation.id,
      title: activeLocation.title || '',
      address: activeLocation.address,
      locationType: activeLocation.locationType,
    },
    coach: activeLesson as never as OmittedCoachType,
    initialBookedLessonId: activeLesson.id,
  };
};

import Image from 'next/image';
import Location from 'svg/Location';
import { LocationCardProps } from '../props';
import { CUSTOM_COURT_IMG_PLACEHOLDER_SRC } from '../utils';

const LessonLocationCard: React.FC<Pick<LocationCardProps, 'activeLocation'>> = ({
  activeLocation,
}) => {
  return (
    <div className="mt-4 flex flex-col">
      <div className="relative h-32 overflow-hidden rounded">
        <Image
          alt="court-image"
          fill
          src={activeLocation?.images?.[0] || CUSTOM_COURT_IMG_PLACEHOLDER_SRC}
        />
      </div>
      <span className="typography-product-element-label mt-3">{activeLocation?.title}</span>
      <div className="mt-3 flex gap-1 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        <Location className="h-4 w-4" />
        <span className="typography-product-text-card">{activeLocation?.address}</span>
      </div>
    </div>
  );
};

export default LessonLocationCard;

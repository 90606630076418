import { useCallback, useEffect, useMemo, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import { AccountType } from 'constants/user';
import {
  useGetActiveOwnerLessonsByIdLazyQuery,
  useGetCoachBookingPageLazyQuery,
  useGetCoachesByGeoLazyQuery,
  useGetFollowedCoachesLazyQuery,
  useGetGroupsLazyQuery,
  useGetUniqueCoachVenueCourtsLazyQuery,
  useGetVenuesByGeoLazyQuery,
} from 'types/generated/client';
import {
  VenueAddress,
  formatCoachVenueData,
  formatNearByVenueData,
} from 'utils/shared/coachBuilder';
import { milesToMeters } from 'utils/shared/geo/milesToMeters';
import { useAuthModals } from 'hooks/useAuthModals';
import { useGeoLocation } from 'hooks/useGeoLocation';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import SafeAreaPage from 'layouts/SafeAreaPage';
import ExploreCourts from 'components/Explore/Courts/ExploreCourts';
import { DEFAULT_COURT_DISTANCE_IMPERIAL } from 'components/Explore/Lessons/utils';
import Footer from 'components/Footer';
import Head from 'components/utilities/Head';
import PopppinsFont from 'components/utilities/PoppinsFont';
import classNames from 'styles/utils/classNames';
import BookLessonDesktop from './BookLessonDesktop';
import BookLessonMobile from './BookLessonMobile';
import ConnectionSection from './ConnectionSection';
import DiscoverButton from './DiscoverButton';
import GroupSection from './GroupsSection';
import HeroSection from './HeroSection';
import MetricSection from './MetricSection';
import PaymentModal from './PaymentModal';
import RoundRobinSection from './RoundRobinSection';
import StatsSection from './StatsSection';
import StayConnectedSection from './StayConnectedSection';
import TournamentSection from './TournamentSection';
import { COURTS_RESULT_LIMIT_FOR_HOMEPAGE } from './constants';
import { CombinedCoachType, HandleSelectSlotFnType, PlayerHomeProps } from './props';
import { formatCoaches } from './utils';

const DEFAULT_COACH_DISTANCE_MILES = 50;
const DEFAULT_VENUES_DISTANCE_MILES = 20;

const PlayerHome: React.FC<PlayerHomeProps> = ({ isServerPlayer, isServerAnonymous }) => {
  const { centerLatitude, centerLongitude, position, getEstimatedLocation } = useGeoLocation();
  const { ModalSignup, ModalLogin, openSignupModal } = useAuthModals();
  const { user } = useGetCurrentUser();
  const isLoggedOut = !user;
  const shouldShowHero = isLoggedOut && isServerAnonymous;
  const paymentModal = useModal();

  const [activeLesson, setActiveLesson] = useState<CombinedCoachType | null>(null);
  const [activeLocation, setActiveLocation] = useState<VenueAddress | null>(null);
  const [registerAccountType, setRegisterAccountType] = useState<AccountType | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [getCoachesByGeoLazyQuery, { data: lessonsData, loading: lessonsLoading }] =
    useGetCoachesByGeoLazyQuery();

  const [getFollowedCoachesLazyQuery, { data: followedLessonData }] =
    useGetFollowedCoachesLazyQuery();

  const [getActiveOwnerLessonsByIdQuery, { data: activeOwnerlessonsData }] =
    useGetActiveOwnerLessonsByIdLazyQuery();

  const [getCoachBookingPageLazyQuery, { data: coachUpdatedData }] =
    useGetCoachBookingPageLazyQuery();

  const [getUniqueCoachVenueCourts, { data: uniqueCoachVenueCourtsData, loading: venuesLoading }] =
    useGetUniqueCoachVenueCourtsLazyQuery();

  const [getGroupsLazyQuery, { data: groupsData, loading: groupsLoading }] =
    useGetGroupsLazyQuery();

  const [getVenuesByGeoLazyQuery, { data: nearbyVenuesData, loading: isLoadingNearbyVenues }] =
    useGetVenuesByGeoLazyQuery();

  const coaches = useMemo(() => {
    if (followedLessonData?.userProfiles?.length) {
      const coachesOnPlatform = followedLessonData?.userProfiles || [];

      const formattedCoaches = formatCoaches({
        coaches: coachesOnPlatform,
        centerLatitude: centerLatitude || 0,
        centerLongitude: centerLongitude || 0,
      });

      return formattedCoaches;
    } else {
      const coachesOnPlatform = lessonsData?.userProfiles || [];
      const coachStartProfiles = lessonsData?.coachStarterProfiles || [];

      const formattedCoaches = formatCoaches({
        coaches: [...coachesOnPlatform, ...coachStartProfiles],
        centerLatitude: centerLatitude || 0,
        centerLongitude: centerLongitude || 0,
      });

      return formattedCoaches;
    }
  }, [lessonsData, centerLatitude, centerLongitude, followedLessonData]);

  const venues = useMemo(() => {
    if (activeLesson && uniqueCoachVenueCourtsData?.coachAvailability) {
      return formatCoachVenueData(uniqueCoachVenueCourtsData?.coachAvailability);
    }
    return [];
  }, [uniqueCoachVenueCourtsData?.coachAvailability, activeLesson]);

  const nearByVenues = useMemo(() => {
    if (nearbyVenuesData?.venues?.length) {
      return formatNearByVenueData(nearbyVenuesData?.venues);
    }
    return [];
  }, [nearbyVenuesData]);

  const coachAvailability = useMemo(() => {
    if (activeLocation) {
      return (
        coachUpdatedData?.userProfiles?.[0]?.coachAvailability.filter(
          (availability) =>
            availability?.coachAvailabilityCustomCourt?.id === activeLocation?.id ||
            availability?.coachAvailabilityVenueId?.id === activeLocation?.id,
        ) || []
      );
    }
    return [];
  }, [activeLocation, coachUpdatedData]);

  const activeOwnerLessons = activeOwnerlessonsData?.lessons || [];

  const handleRegistration = (accountType: AccountType) => {
    setRegisterAccountType(accountType);
    openSignupModal();
  };

  const handleSelectSlot = useCallback<HandleSelectSlotFnType>((date) => {
    setSelectedDate(date);
  }, []);

  const handleBookLesson = useCallback(() => {
    paymentModal.openModal();
  }, [paymentModal]);

  const handleBookAnotherLesson = useCallback(() => {
    setSelectedDate(null);
    paymentModal.closeModal();
  }, [paymentModal]);

  const handlePaymentSuccess = async () => {
    await getCoachBookingPageLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: activeLesson?.id,
        username: activeLesson?.id,
        startDateTime: new Date().toISOString(),
      },
    });
  };

  useEffect(() => {
    if (user?.id) {
      getFollowedCoachesLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: user?.id,
        },
      });
    } else {
      const getCoaches = async () => {
        try {
          await getCoachesByGeoLazyQuery({
            fetchPolicy: 'network-only',
            variables: {
              distance: milesToMeters(DEFAULT_COACH_DISTANCE_MILES),
              from: {
                type: 'Point',
                coordinates: [position!.longitude, position!.latitude],
              },
            },
          });
        } catch (err) {
          captureException(err);
        }
      };

      position && getCoaches();
    }
  }, [user?.id, position]);

  useEffect(() => {
    coaches.length > 0 && setActiveLesson(coaches[0]);
  }, [coaches]);

  useEffect(() => {
    if (venues.length > 0) setActiveLocation(venues[0]);
    else if (venues.length === 0) {
      setActiveLocation(null);
    }
  }, [venues]);

  useEffect(() => {
    const getCoaches = async () => {
      try {
        await getCoachesByGeoLazyQuery({
          fetchPolicy: 'network-only',
          variables: {
            distance: milesToMeters(DEFAULT_COACH_DISTANCE_MILES),
            from: {
              type: 'Point',
              coordinates: [position!.longitude, position!.latitude],
            },
          },
        });
      } catch (err) {
        captureException(err);
      }
    };

    position && followedLessonData?.userProfiles.length === 0 && getCoaches();
  }, [followedLessonData]);

  useEffect(() => {
    const getVenues = async () => {
      try {
        await getVenuesByGeoLazyQuery({
          fetchPolicy: 'network-only',
          variables: {
            distance: milesToMeters(DEFAULT_VENUES_DISTANCE_MILES),
            from: {
              type: 'Point',
              coordinates: [position!.longitude, position!.latitude],
            },
          },
        });
      } catch (err) {
        captureException(err);
      }
    };

    position && getVenues();
  }, [getVenuesByGeoLazyQuery, position]);

  useEffect(() => {
    const getGroups = async () => {
      await getGroupsLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
          limit: 50,
          from: {
            type: 'Point',
            coordinates: [position!.longitude, position!.latitude],
          },
          distance: milesToMeters(DEFAULT_COURT_DISTANCE_IMPERIAL.id),
        },
      });
    };

    position && getGroups();
  }, [getGroupsLazyQuery, position]);

  useEffect(() => {
    const init = async () => {
      await getUniqueCoachVenueCourts({
        fetchPolicy: 'cache-and-network',
        variables: {
          userId: activeLesson?.id,
        },
      });
      setSelectedDate(null);
    };
    activeLesson && init();
  }, [activeLesson]);

  useEffect(() => {
    const init = async () => {
      await getActiveOwnerLessonsByIdQuery({
        variables: { ownerUserId: activeLesson?.id },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
      });

      await getCoachBookingPageLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          id: activeLesson?.id,
          username: activeLesson?.id,
          startDateTime: new Date().toISOString(),
        },
      });
    };
    activeLesson?.id && init();
  }, [activeLesson]);

  useEffect(() => {
    if (!position) {
      getEstimatedLocation();
    }
  }, [position]);

  return (
    <>
      <PopppinsFont />
      <Head
        title="The Pickleball App"
        description="Take pickleball lessons, create round robins, register for tournaments, find courts near you, and manage your pickleball group. Pickleball runs through Bounce."
      />
      <SafeAreaPage isShowTopNav isHideSidebar>
        <main>
          {shouldShowHero && (
            <>
              <HeroSection />
              <DiscoverButton />
              <StatsSection />
              <ConnectionSection handleRegistration={handleRegistration} />
              <MetricSection />
            </>
          )}
          <div
            className={classNames(
              'h-full lg:rounded-t-[2.5rem]',
              shouldShowHero
                ? 'mt-ds-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.16)] lg:mt-20'
                : 'mt-ds-xl',
            )}
          >
            <BookLessonDesktop
              setActiveLocation={setActiveLocation}
              activeLocation={activeLocation}
              setActiveLesson={setActiveLesson}
              activeLesson={activeLesson}
              lessonsLoading={lessonsLoading}
              venuesLoading={venuesLoading}
              coachAvailability={coachAvailability}
              activeOwnerLessons={activeOwnerLessons}
              handleSelectSlot={handleSelectSlot}
              lessons={coaches}
              venues={venues}
              selectedDate={selectedDate}
              handleBookLesson={handleBookLesson}
              nearbyVenues={(nearByVenues || []).slice(0, 5)}
            />
            <BookLessonMobile
              setActiveLocation={setActiveLocation}
              coachAvailability={coachAvailability}
              activeOwnerLessons={activeOwnerLessons}
              activeLocation={activeLocation}
              setActiveLesson={setActiveLesson}
              activeLesson={activeLesson}
              lessonsLoading={lessonsLoading}
              venuesLoading={venuesLoading}
              handleSelectSlot={handleSelectSlot}
              lessons={coaches}
              venues={venues}
              selectedDate={selectedDate}
              handleBookLesson={handleBookLesson}
              nearbyVenues={(nearByVenues || []).slice(0, 5)}
            />
            <div className="mx-6 my-7 border-t lg:hidden" />
            <TournamentSection />
            {/* hid this divider if there are no tournaments */}
            <div className="mx-6 my-7 block border-t peer-[.hidden]/tournaments:hidden md:hidden" />
            <GroupSection groups={groupsData?.groups || []} loading={groupsLoading} />
            <ExploreCourts resultsLimit={COURTS_RESULT_LIMIT_FOR_HOMEPAGE} />
            <RoundRobinSection />
            <StayConnectedSection />
          </div>
        </main>
        <Footer />
      </SafeAreaPage>
      <ModalSignup isShowLoginLink defaultAccountType={registerAccountType!} />
      <ModalLogin isShowSignupLink />
      <PaymentModal
        selectedDate={selectedDate}
        activeLocation={activeLocation}
        activeLesson={activeLesson}
        isOpen={paymentModal.isOpen}
        handleClose={paymentModal.closeModal}
        onPaymentSuccess={handlePaymentSuccess}
        handleBookAnotherLesson={handleBookAnotherLesson}
      />
    </>
  );
};

export default PlayerHome;

import { useMemo } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { pluralize } from 'utils/shared/pluralize';
import Location from 'svg/Location';
import Price from 'svg/Price';
import { ButtonLink } from 'components/Button';
import classNames from 'styles/utils/classNames';
import { LessonCardProps } from './props';
import { isCoachProfile } from './utils';

const MobileInnerDiv = styled.div`
  transition: 0.05s top, 0.05s border-color 0.05s;
`;

const MobileBtnDiv = styled.div`
  transition: 0.1s height 0.1s;
`;

const Mobile: React.FC<LessonCardProps> = ({ isActive, onClick, lesson }) => (
  <div className={classNames('relative flex h-[16rem]')}>
    <MobileInnerDiv
      onClick={onClick}
      className={classNames(
        'absolute top-1/2 w-full -translate-y-1/2 cursor-pointer rounded-md border-2',
        isActive
          ? 'top-0 border-color-border-input-darkmode dark:border-color-border-input-lightmode'
          : 'border-transparent',
      )}
    >
      <div className="relative h-[7.5rem] overflow-hidden rounded-t-md duration-100">
        <Image
          alt="coach-image"
          src={lesson.imageSrc}
          fill
          objectFit="cover"
          objectPosition="top"
        />
      </div>
      <div className="rounded-b-md bg-color-bg-lightmode-secondary p-2 dark:bg-color-bg-darkmode-secondary">
        <span className="typography-product-button-label-xs overflow-ellipsis whitespace-nowrap text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {isCoachProfile(lesson) ? lesson.fullName : lesson.name || ''}
        </span>
        <div className="mt-1 flex gap-1">
          <Price className="h-4 w-4 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary" />
          <span className="typography-product-text-card overflow-ellipsis whitespace-nowrap text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            {lesson.price || 0}$ per lesson
          </span>
        </div>
        <MobileBtnDiv
          style={{ transition: '0.1s height 0.1s' }}
          className={classNames(isActive ? 'h-9' : 'h-0', 'mt-2 overflow-hidden')}
        >
          <ButtonLink
            href={lesson.href}
            variant="inverted"
            sizeMobile="sm"
            className="bg-transparent"
          >
            View Profile
          </ButtonLink>
        </MobileBtnDiv>
      </div>
    </MobileInnerDiv>
  </div>
);

const Desktop: React.FC<LessonCardProps> = ({ isActive, onClick, lesson }) => {
  const { address, formattedDistance } = useMemo(() => {
    const formattedDistance = isCoachProfile(lesson)
      ? pluralize({
          count: Math.round(lesson.distance || 0),
          singular: 'mile',
          plural: 'miles',
        })
      : '';

    const address = isCoachProfile(lesson)
      ? lesson.defaultCoachCustomCourt?.fullAddress || lesson.defaultCoachVenue?.addressString || ''
      : '';

    return {
      address,
      formattedDistance,
    };
  }, [lesson]);

  const getTextWithIcon = (
    Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
    text: string | number,
  ) => (
    <div className="flex min-w-0 gap-1">
      {<Icon className="h-4 w-4 shrink-0 text-color-text-lightmode-tertiary" />}
      <span
        dangerouslySetInnerHTML={{ __html: text }}
        className="typography-product-text-card-desktop  truncate  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
      />
    </div>
  );

  return (
    <>
      <div
        onClick={onClick}
        className={classNames(
          'flex h-full w-full cursor-pointer items-start gap-6 rounded-xl border bg-color-bg-lightmode-primary pr-ds-lg transition-shadow dark:bg-color-bg-darkmode-primary max-xl:hidden',
          isActive
            ? 'border-color-border-input-lightmode shadow-lightmode-primary dark:border-color-border-input-darkmode'
            : 'border-transparent',
        )}
      >
        <div className="relative  h-full w-20 shrink-0 overflow-hidden rounded-xl">
          <div className="h-full w-full">
            <Image src={lesson.imageSrc} alt="lesson-image" fill objectFit="cover" />
          </div>
          {/* no rating available for now */}
          {/* <div className="absolute bottom-0 right-0 flex items-center gap-1 rounded-tl-lg bg-color-bg-lightmode-primary p-1 dark:bg-color-bg-darkmode-primary">
            <Star className="h-4 w-4" />
            <span className="text-xs"></span>
          </div> */}
        </div>
        <div className="flex min-w-0 flex-1 items-center justify-between gap-1 py-2">
          <div className="flex min-w-0 flex-col gap-1">
            <span className="typography-product-card-title-desktop truncate">
              {isCoachProfile(lesson) ? lesson.fullName : lesson.name || ''}
            </span>
            {getTextWithIcon(Price, `${lesson.price} + per Lesson`)}
            {getTextWithIcon(Location, `${address} &bull; ${formattedDistance}`)}
          </div>
          <div className="ml-auto shrink-0">
            <ButtonLink href={lesson.href} variant="inverted" size="sm">
              View Profile
            </ButtonLink>
          </div>
        </div>
      </div>

      {/* for tablets */}

      <div
        onClick={onClick}
        className={classNames(
          'hidden flex-col rounded-lg border max-xl:flex',
          isActive
            ? 'border-color-border-input-darkmode dark:border-color-border-input-lightmode'
            : 'border-transparent',
        )}
      >
        <div className="relative h-64 overflow-hidden rounded-lg">
          <Image
            src={lesson.imageSrc}
            alt="lesson-image"
            fill
            objectFit="cover"
            objectPosition="top"
          />
        </div>
        <div className="flex flex-col gap-1 px-2 py-2">
          <span className="typography-product-card-title-desktop">
            {isCoachProfile(lesson) ? lesson.fullName : ''}
          </span>
          {getTextWithIcon(Price, `${lesson.price} + per Lesson`)}
          {getTextWithIcon(Location, `${address} &bull; ${formattedDistance}`)}
        </div>
      </div>
    </>
  );
};

const LessonCard = {
  Desktop,
  Mobile,
};

export default LessonCard;

import { GetVenuesByGeoQuery } from 'types/generated/client';
import { FilterProps as CourtFinderFilterProps } from 'screens/CourtFinder/types';

export interface CourtListProps {
  venues: GetVenuesByGeoQuery['venues'];
  listHoveredVenueId: string | null;
  setListHoveredVenueId: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean;
}

export interface CourtMapMarkerProps {
  listHoveredVenueId: string | null;
  setListHoveredVenueId: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveVenueId: React.Dispatch<React.SetStateAction<string | null>>;
  activeVenue: GetVenuesByGeoQuery['venues'][0] | undefined;
  venue: GetVenuesByGeoQuery['venues'][0];
}

export interface CourtFiltersProps extends CourtFinderFilterProps {
  courtLimit: number;
  centerLongitude: number;
  centerLatitude: number;
  distance: {
    name: string;
    id: number;
  };
  setDistance: (distance: { name: string; id: number }) => void;
}

export enum CourtType {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
}

export interface ExploreCourtsProps {
  resultsLimit?: number;
}
